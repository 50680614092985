@import "../../variables.scss";

.overview-list {
  display: flex;
  flex-direction: column;
  height: 100%;

  .row.filters-row {
    flex: none;

    .header {
      padding-bottom: $space-large;
    }

    .freetext-filter {
      margin-bottom: $space-large;
    }
  }

  .row.list-row {
    flex: 1;
    display: flex;

    .list-container {
      flex: 1;
    }
  }
}
