@import "../../../../styling/variables.scss";

/* HELPERS / MIXIN */
@mixin flexbox() {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.d-flex {
  @include flexbox();
}

.rm-summary-list {
  margin: 4px 0;

  .rm-summary-item {
    @include flexbox();
    justify-content: space-between;
    align-items: flex-start;
    padding: 13px 0 14px 0;
    border-bottom: 1px dotted #bfbfbf;
    margin: 0 16px;
  }
}
