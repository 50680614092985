@import "../../../../styling/variables.scss";
.material-transfer-validator-container {
  .item-enter {
    opacity: 0;
  }
  .item-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
  }
  .item-exit {
    opacity: 1;
  }
  .item-exit-active {
    opacity: 0;
    transition: opacity 300ms ease-in;
  }
}
.material-transfer-error {
  text-align: center;
}
.right-align-content {
  display: flex;
  justify-content: flex-end;
}
div.wizard {
  overflow-x: hidden;

  div.wizard-footer {
    padding-bottom: 8px;
    padding-right: 8px;
    padding-left: 8px;
    padding-top: 8px;
    flex: 1;
  }

  div.wizard-steps {
    margin-top: $space-xxl;
    flex: 1;
  }

  div.wizard-content {
    margin-top: 0;
    margin-bottom: 0;
    flex: 4;
    div.content {
      margin-left: 8px;
      margin-right: 8px;
      .wizard-page-title {
        display: flex;
        flex-direction: row;
        align-items: center;
        .title-text {
          flex: 1;
        }
      }
      > div.title,
      > div.subtitle {
        display: none;
      }
    }
  }
}
.dark .material-transfer-validator-container {
  background-color: $color-tile-dark;
}

.light .material-transfer-validator-container {
  background-color: $color-tile-light;
}

.appcontent
  .centered-container
  .centered-content
  .material-transfer-error
  .btn-icon.api-call-retry-button {
  margin: 10px auto;
}
