@import "../../../../styling/variables.scss";

/* HELPERS / MIXIN */
@mixin flexbox() {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.d-flex {
  @include flexbox();
}

.rm-deviation-summary-list-dialog {
  .dialog {
    height: 100%;
    .content {
      height: 100%;
      .top {
        border-bottom: 1px solid rgb(87, 87, 87);
        .title {
          flex: 0 0 auto;
          font-size: 16px;
          font-weight: 400;
          padding-bottom: 12px;
        }
      }
      .body {
        padding: 20px 0 15px 0;
        height: calc(100% - 70px);
        .rm-deviation-summary-list {
          text-align: justify;
          overflow-y: auto;
          overflow-x: hidden;
          height: 100%;

          .rm-deviation-summary-list-item {
            margin-bottom: 10px;
          }
        }
      }
      .bottom {
        display: flex !important;
        flex: 1 1 !important;
        min-height: 36px;
        flex-direction: column;
        align-content: space-around;
        justify-content: flex-start;
        align-items: stretch;
        .btn {
          margin: 4px 0 !important;
          display: block !important;
        }
      }
    }
  }

  &.rm-sm .dialog .content {
    height: auto;
  }
  &.rm-md .dialog .content {
    height: 460px;
  }
}
