@import "../../../../../styling/variables.scss";

.quantity-validator-title {
  font-size: $text-default;
  padding-bottom: $space-large;
  font-weight: 600;
  padding-top: $space-large;
  line-height: 28px;
}

.quantity-tile {
  .quantity-container {
    display: flex;
    align-items: center;
  }
  .quantity-deviation-row {
    margin-top: $space-small;
  }
  .quantity-deviation-buttons {
    margin-top: $space-large;
  }
  .text-field-with-suffix {
    width: 100%;
  }
}

.quantity-tile + .validated-items-title {
  // padding-top: $space-xxl;
}
.dark {
  .quantity-validator-title {
    border-bottom: 1px solid $color-border-dark;
  }
  // .quantity-tile + .validated-items-title {
  //   border-top: 1px solid $color-border-dark;
  // }
}
.light {
  .quantity-validator-title {
    border-bottom: 1px solid $color-border-light;
  }
  // .quantity-tile + .validated-items-title {
  //   border-top: 1px solid $color-border-light;
  // }
}
