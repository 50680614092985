@import "../../../styling/variables.scss";

.overview-list-item {
  .overview-content {
    .to-and-from-company-name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
.material-transfers-pill-filter {
  margin-top: $space-large;
  display: flex;
  justify-content: space-around;
  & > span.pill {
    display: inline-block;
  }
}

.centered-container {
  .centered-content {
    .material-transfer-api-error {
      text-align: center;
      .material-transfer-retry-button {
        margin-top: $space-base;
      }
    }
  }
}
