//EDS Light colors
$color-system-light: #0c0c0c;
$color-application-light: #ebebeb;
$color-tile-light: #fafafa;
$color-text-light: #242424;
$color-gray-text-light: #6a6a6a;
$color-link-blue-light: #0069c2;
$color-purple-light: #a56ebe;
$color-blue-light: #1174e6;
$color-red-light: #ed0e00;
$color-green-light: #329864;
$color-orange-light: #d46617;
$color-yellow-light: #a08209;
$color-gray-light: #878787;
$color-data-1-light: #333333;
$color-data-2-light: #4a667e;
$color-data-3-light: #23969a;
$color-data-4-light: #976cf4;
$color-data-5-light: #7f87ad;
$color-border-light: #c8c8c8;
$color-disabled-input-background-light: #ebebeb;
$color-disabled-input-color-light: rgba(36, 36, 36, 0.6);

//EDS Dark colors
$color-system-dark: #0c0c0c;
$color-application-dark: #181818;
$color-tile-dark: #242424;
$color-text-dark: #f2f2f2;
$color-gray-text-dark: #8b8b8b;
$color-link-blue-dark: #008cff;
$color-purple-dark: #8e45b0;
$color-blue-dark: #0077db;
$color-red-dark: #dc2d37;
$color-green-dark: #288964;
$color-orange-dark: #e66e19;
$color-yellow-dark: #dcaf00;
$color-gray-dark: #adadad;
$color-data-1-dark: #ffffff;
$color-data-2-dark: #a6abc6;
$color-data-3-dark: #74dcdf;
$color-data-4-dark: #ba9ef8;
$color-data-5-dark: #557791;
$color-border-dark: #4e4e4e;
$color-disabled-input-background-dark: #2d2d2d;
$color-disabled-input-color-dark: rgba(242, 242, 242, 0.6);

//Custom dark colors
$color-tile-dark-card: #333;
$color-box-shadow-dark: #ffffff4d;
$color-nested-background-dark: rgba(50, 50, 50, 0.5);

//Custom light colors
$color-tile-light-card: #ffffff;
$color-box-shadow-light: #0000004d;

// EDS Spaces
$space-small: 4px;
$space-base: 8px;
$space-large: 16px;
$space-xl: 24px;
$space-xxl: 32px;

// EDS Font Sizes
$text-xl: 28px;
$text-lg: 16px;
$text-default: 14px;
$text-sm: 12px;

// ESR Font Sizes
$text-size-subtitle: 0.7em;

// Interpretations/bug fixes
$color-dropdown-btn-background-dark: #00000026;
$color-dropdown-btn-background-light: #ffffff;
