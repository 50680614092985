@import "../../../styling/variables.scss";
.dialog.show > .content.product-details-dialog {
  overflow-y: scroll;
}
.product-details-modal-row {
  margin-bottom: $space-small;
  .product-details-modal-title {
    font-size: 0.9em;
    font-weight: bolder;
  }

  .product-details-modal-value {
    word-wrap: break-word;
  }
}

.light {
  .product-details-modal-row {
    .product-details-modal-title {
      color: $color-gray-text-light;
    }
  }
}

.dark {
  .product-details-modal-row {
    .product-details-modal-title {
      color: $color-gray-text-dark;
    }
  }
}
