@import "../../../../../../styling/variables.scss";

.actions-icon + .actions-icon {
  margin-left: $space-base;
}

.actions-icon {
  align-self: center;
}
.quantity-tile {
  margin-bottom: $space-large;
}

.dark {
  .quantity-tile + .quantity-tile {
    border-top: 1px solid $color-border-dark;
  }
  .quality-validator-item-subtitle {
    color: $color-gray-text-dark;
  }
  .quality-validator-item-description {
    color: $color-gray-text-dark;
  }
}

.light {
  .quantity-tile + .quantity-tile {
    border-top: 1px solid $color-border-light;
  }
  .quality-validator-item-subtitle {
    color: $color-gray-text-light;
  }
  .quality-validator-item-description {
    color: $color-gray-text-light;
  }
}

.quality-validator-item-header {
  margin-top: $space-base;
  .quality-validator-item-title {
    align-self: center;
  }
  .quality-validator-item-subtitle {
    align-self: center;
  }
  .quality-validator-item-description {
    align-self: center;
  }
}

.quality-validator-item-title {
  font-size: $text-default;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.quality-validator-item-subtitle {
  font-size: $text-size-subtitle;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.quality-validator-item-description {
  font-size: 0.7em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-top: 0.5em;
}
.quantity-product-number {
  font-size: $text-sm;
}
.space-align {
  justify-content: space-around;
  display: flex;
}
