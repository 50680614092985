.dialog .body {
  display: flex;
  flex-direction: column;

  .btn {
    min-height: 3.5em;
    margin: 10px;
  }
  .light-btn-close {
    min-height: 3.5em;
    margin: 10px !important;
  }
}
