@import "../../../styling/variables.scss";

.display--flex-row {
  display: flex;
  flex-direction: row;
}

.padding--15px {
  padding: 15px;
}

.btn--rectangle {
  border-radius: 0 !important;
}

.scanner-box {
  margin: 15px;
  text-align: center;
  &--image {
    height: 30vh;
    border: 1px dashed;
    border-radius: 5px;
  }
  &--text {
    margin-top: 15px;
  }

  &--helper-text {
    margin: 0;
  }

  &__tile {
    width: 100%;
    text-align: center;
    padding: 10px;
    input[type="text"] {
      margin: 0;
      flex: 1 1;
    }
    &--btn-small {
      min-width: 60px !important;
      margin-left: 0px;
      flex-basis: 20%;
      flex-grow: 0;
    }
  }
}

.scanner-box-input-wrap {
  display: flex;
  flex-flow: row wrap;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: flex-end;
}

.dark .scanner-box__tile--btn-small:disabled {
  border-color: #6a6a6a;
  background-color: #6a6a6a;
}

.sample-barcode-container {
  height: 100%;
  display: flex;
  align-items: center;
  &__tile {
    padding: 20% 5% 10% 5% !important;
    &-flex {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &-img {
      width: 100%;
    }
    &-btn {
      width: "100%";
    }
  }
}

.receive-scanner-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.receive-scanner-spacing {
  height: 10vh;
}

.rm-container.list-transfers .rm-footer .rm-row .btn,
.receive-scanner-wrapper .scanner-box-input-wrap .btn.primary,
.receive-scanner-wrapper .scanner-box__tile input[type=text]{
  margin-top: 2px !important;
  margin-bottom: 2px !important;
}

.receive-scanner-wrapper .scanner-box-input-wrap .btn.primary{
  margin-left: -1px !important;
  opacity: 1 !important;
}

.light .receive-scanner-wrapper .scanner-box-input-wrap .btn.primary:disabled {  
  background-color: #9dc4f2;
  border-color:#878787;
}

.receive-scanner-wrapper .scanner-box__tile input[type=text] {
  border-radius: 0 !important;  
}