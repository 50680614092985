@import "../../../styling/variables.scss";

@keyframes searching {
  0% {
    border-color: rgba(255, 255, 255, 1);
  }

  10% {
    border-color: rgba(255, 255, 255, 1);
    transform: scale(1, 1);
  }

  20% {
    border-color: rgba(255, 255, 255, 0.25);
    transform: scale(0.99, 0.99);
  }

  30% {
    border-color: rgba(255, 255, 255, 1);
    transform: scale(1, 1);
  }
}
.app .tile.scanner-container {
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  * {
    user-select: none;
  }
  .material-design-action-icon-fix {
    vertical-align: middle;
    width: 1.3em;
  }
  .header {
    padding-bottom: 0;
    .title {
      margin-bottom: $space-large;
    }
    .left .icon {
      margin-right: $space-base;
    }
  }

  .viewfinder {
    position: absolute;
    border: 2px solid white;
    border-radius: 4px;
    z-index: 99;
    &.idle {
      border-style: dotted;
      opacity: 0.6;
    }
    &.searching {
      animation: searching 2s infinite;
    }
  }

  .status-text {
    position: absolute;
    bottom: 20px;
    width: 100%;
    color: white;
    text-shadow: 1px 1px 1px black;
    font-size: 16px;
    text-align: center;
    z-index: 99;
  }
  .change-camera-icon {
    position: absolute;
    bottom: 10px;
    left: 4px;
    text-align: center;
    z-index: 99;
    color: white;
  }

  .tap-to-scan {
    position: absolute;
    width: 75px;
    height: 75px;
    border: 2px solid white;
    box-shadow: 0px 0px 3px black, inset 0px 0px 3px black;
    bottom: $space-base;
    left: 50%;
    margin-left: -37.5px;
    z-index: 1;
    border-radius: 50%;
    &.awaiting {
      animation: searching 2s infinite;
    }
    &.active {
      border-color: $color-green-dark;
    }
  }
  .scan-status {
    position: absolute;
    bottom: 91px;
    left: 0;
    right: 0;
    color: white;
    text-shadow: 1px 1px 1px black;
    font-size: 16px;
    text-align: center;
    z-index: 99;
  }
  .top-content {
    position: absolute;
    top: 35px;
    left: 0;
    right: 0;
    z-index: 100;
    padding-top: 0px;
    padding-right: $space-large;
    padding-bottom: 0px;
    padding-left: $space-large;
    display: flex;
    flex-direction: column;
    margin: $space-large 0;
    transition: right 0.5s ease-in-out;
    > div {
      border-top: 1px solid #585858;
      + div {
        border-top: none;
      }
    }
    .feedback {
      padding-top: $space-large;
      padding-right: 0px;
      padding-bottom: $space-large;
      padding-left: 0px;
      display: flex;
      align-items: center;
      .icon {
        margin-right: $space-base;
      }
    }
    .result {
      > :first-child {
        padding-top: $space-base;
      }
      .quantities {
        margin-top: $space-base;
      }
      .text-field-with-button-container {
        display: flex;
        .text-field-container {
          flex: 1;
          margin-right: -12px;
        }
        .btn {
          margin-left: 12px;
        }
      }
      .text-field-with-suffix {
        max-width: 50%;
      }

      .progress-bar {
        margin-top: 0px;
        margin-right: -$space-large;
        margin-bottom: 0px;
        margin-left: -$space-large;
        line-height: $space-base;
        progress {
          width: 100%;
        }
      }
    }
  }
  // TODO: Figure out whether this is ok or not
  .scandit .scandit-logo {
    bottom: 8px;
    right: 8px;
  }
}
.dark {
  .material-design-action-icon-fix {
    &:hover {
      svg {
        fill: $color-text-dark;
      }
    }
    svg {
      fill: $color-gray-text-dark;
    }
  }
  .app .tile.scanner-container .top-content {
    background: $color-tile-dark;
    .feedback {
      &.error {
        color: $color-red-dark;
      }
      &.warning {
        color: $color-yellow-dark;
      }
      &.information {
      }
    }
  }
}
.light {
  .material-design-action-icon-fix {
    &:hover {
      svg {
        fill: $color-text-light;
      }
    }
    svg {
      fill: $color-gray-text-light;
    }
  }
  .app .tile.scanner-container .top-content {
    background: $color-tile-light;
    .feedback {
      &.error {
        color: $color-red-light;
      }
      &.warning {
        color: $color-yellow-light;
      }
      &.information {
      }
    }
  }
}
