@import "./styling/components/index.scss";

#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.update-notification {
  background-color: #ffffff;
  color: #222222;
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0.8em 1.2em;
  text-decoration: none;
}

.app-bar-item {
  margin-right: 16px;
  opacity: 0.8;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.app-bar-app-icon {
  display: inline-block;
  left: 0;
  top: -1px;
  margin-right: 0;
  position: relative;
  font-size: 16px;
  cursor: pointer;
}
.show-more {
  display: inline-block;
  margin-top: $space-xl;
}

.custom-setting-container {
  margin-top: 5px;
  .left {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-right: 20px;
  }
  .select {
    min-width: auto;
  }
}

.light {
  .red {
    color: $color-red-light;
  }

  .yellow {
    color: $color-yellow-light;
  }

  .green {
    color: $color-green-light;
  }
}

.dark {
  .red {
    color: $color-red-dark;
  }

  .yellow {
    color: $color-yellow-dark;
  }

  .green {
    color: $color-green-dark;
  }
}

.centered-text {
  text-align: center;
}

/* EDS Overrides */
.syspanel .settings .content .item {
  margin-bottom: 4px;

  & > div {
    align-self: center;
  }
}

button.btn-icon {
  display: flex;

  i.btn-icon {
    align-self: center;
  }
}

.signin .welcome .loading {
  text-align: left;
}

/* Safari specific overrides */
.dark .app {
  input:not([type="checkbox"]):not([type="radio"]):read-only,
  textarea:read-only,
  input:not([type="checkbox"]):not([type="radio"])[readonly],
  textarea[readonly] {
    background: $color-disabled-input-background-dark;
    color: $color-disabled-input-color-dark;
    border-color: $color-border-dark;
    padding-left: 6px;
    border-radius: 0;
    &:hover {
      border-color: $color-border-dark;
    }
  }
}
.light .app {
  input:not([type="checkbox"]):not([type="radio"]):read-only,
  textarea:read-only,
  input:not([type="checkbox"]):not([type="radio"])[readonly],
  textarea[readonly] {
    background: $color-disabled-input-background-light;
    color: $color-disabled-input-color-light;
    border-color: $color-border-light;
    padding-left: 6px;
    border-radius: 0;
    &:hover {
      border-color: $color-border-light;
    }
  }
}

input::-webkit-inner-spin-button {
  display: none;
}

input {
  opacity: 1;
}
// Temporary solution to fix safari loader-issue
.loading.medium.login-loader {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.signin .welcome .loading {
  display: none;
}
