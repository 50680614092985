.file-upload-btn-container {
  position: relative;

  input[type="file"] {
    top: 0;
    left: 0;
  }
  .file-upload-btn-disabled {
    opacity: 0.4;
    cursor: default;
  }
}
