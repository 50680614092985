@import "../../../../../../styling/variables.scss";

.serial-number-item-container {
  padding: 10px 20px 10px 20px;

  .serial-number-action-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  &:nth-child(1) {
    padding-top: 16px;
  }
  .light &:nth-child(1){
    border-bottom: 1px solid $color-border-light;
  }
  .dark &:nth-child(1){
    border-bottom: 1px solid $color-border-dark;
  }
  + .serial-number-item-container {
    padding-top: $space-large;
  }
  .serial-number-subtitle {
    font-size: $text-size-subtitle;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .serial-number-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .justify-content-right {
    display: flex;
    justify-content: flex-end;
  }
  .inline-block {
    display: inline-block;
  }
  .serial-number-row {
    margin-top: $space-small;
    display: flex;
    .serial-number-btn-wrap {
      display: flex;
      flex-direction: column-reverse;
      flex-wrap: wrap;
      align-content: flex-end;
      .btn {
        border-radius: 0 !important;
        margin-left: -1px;
      }
    }
    &:disabled {
      border-color: #6a6a6a;
      background-color: #6a6a6a;
    }
    .serial-number-field {
      justify-self: flex-start;
      flex: 1;
      input {
        margin-right: 0;
        width: 100%;
        border-radius: 0;
      }
    }
  }
  div.scan-button button.btn {
    margin-left: 8px;
  }
  .validation-button-row {
    margin-top: $space-base;
  }
  .validation-status {
    font-size: $text-sm;
    margin-right: $space-base;
    padding-bottom: 8px;
    display: block;
  }
  .scan-button {
    display: flex;
    button {
      align-self: flex-end;
    }
  }
}

.serial-number-item-container + .item-list-header {
  margin-top: $space-base;
}

.dark {
  .serial-number-subtitle {
    color: $color-gray-text-dark;
  }
  .serial-number-item-container + .serial-number-item-container {
    background: #242424;
  }
  .serial-number-item-container {
    .btn.primary {
      color: #fff;
      background-color: #424242;
      border: 1px solid #767676;
    }
  }
}

.light {
  .serial-number-subtitle {
    color: $color-gray-text-light;
  }
  .serial-number-item-container .btn.primary {
    color: #242424;
    background-color: #ebebeb;
    border: 1px solid #878787;
  }
}
