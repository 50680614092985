@import "../../../../styling/variables.scss";

/* HELPERS / MIXIN */
@mixin flexbox() {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.d-flex {
  @include flexbox();
}

.rm-container {
  min-height: 100%;
  @include flexbox();
  flex-direction: column;
  align-items: stretch;
}

.rm-header {
  max-height: 48px;
  padding: 15px 16px 15px 16px;

  input[type="checkbox"] + label::before {
    margin-right: 10px !important;
  }

  &.sticky {
    position: sticky;
    top: 0;
    border-bottom: solid 4px #181818;
  }
}

.rm-footer {
  min-height: 50px;
  padding: 11px 8px;
  position: sticky !important;
  width: 100%;
  bottom: 0 !important;

  .btn {
    width: 100%;
    text-align: center;
  }
  .column.sm-6:nth-child(1) {
    padding-right: 4px;
  }

  .rm-transfer-list-row {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;

    .rm-back-btn-column {
      max-width: 45%;
      padding-right: 8px;
      .btn {
        width: 100%;
      }
    }

    .rm-validate-btn-column {
      max-width: 45%;
      padding-right: 8px;
      .btn {
        width: 100%;
      }
    }

    .rm-menu-column {
      max-width: 10%;
      display: flex;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: space-around;
      .dropdown.more .menu {
        min-width: 50vw;
        bottom: 36px;
      }
    }
  }
}
.rm-body {
  flex: 1;
  .rm-transfer-list {
    padding: 14px;
  }
}

.rm-row {
  @include flexbox();
  justify-content: space-between;
}

.dark .rm-header,
.dark .rm-footer {
  background: #242424;
}

.light .rm-header,
.light .rm-footer {
  background: #fafafa;
}

.list-transfers {
  .tile {
    padding: 10px 16px 10px 16px;
  }
  > :not(:last-child) {
    margin-bottom: 4px;
  }

  .rm-transfer-item {
    @include flexbox();
    justify-content: space-between;
    align-items: flex-start;

    .rm-input {
      width: 24px;
      padding-top: 2px;
    }

    .rm-content {
      flex: 1;
      @include flexbox();
      flex-direction: column;
      align-items: flex-start;

      .rm-company {
        .title {
          font-size: 16px;
          font-weight: 400;
        }

        .subtitle {
          font-size: 12px;
          text-align: left;
          padding: 0px 0;
        }
      }

      :hover {
        cursor: pointer;
      }

      .rm-info {
        @include flexbox();
        width: 160px;
        justify-content: space-between;
        margin-top: 10px;
      }
    }

    .rm-actions {
      @include flexbox();
      flex-direction: column;
      align-items: flex-end;
      margin-top: 2px;

      .rm-actions-bottom {
        margin-top: 4px;
        .rm-actions-bottom-deviations {
          margin-top: 5px;
        }
        .icon {
          margin: 0px;
          cursor: pointer;
        }
      }
    }
  }
}

.rm-container.list-transfers {
  position: relative !important;

  .rm-footer {
    .rm-row {
      margin-bottom: 4px !important;
    }
  }
}

.dark .rm-content .subtitle {
  color: #8b8b8b;
}
.light .rm-content .subtitle {
  color: #6a6a6a;
}

@media only screen and (max-width: 48em) {
  aside:not(.hidden) {
    + .app .rm-footer {
      position: relative !important;
    }

    + .app .rm-container {
      padding-bottom: 0 !important;
    }
  }
}
