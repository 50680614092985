.overview-list-item .tile.material-transfer-details-item {
  padding: 0;
  padding-left: 12px;

  .content {
    display: flex;
    font-size: 12px;
    margin-bottom: 14px;
    padding-left: 0.5rem !important;
  }
  .content .quantity {
    margin-right: 10px;
    margin-left: auto;
  }

  .header {
    padding-bottom: 0.5em;
    .left {
      display: flex;
      flex-direction: column;

      .subtitle {
        display: flex;
        padding-left: 0.5rem;
      }

      .title .material-transfer-details-item-title {
        display: flex;
        flex-grow: 1;
        justify-content: space-between;
        align-items: center;
      }
      .title::before {
        content: "";
      }
      span.title {
        pointer-events: none;
        padding-bottom: 0;
      }
    }

    .right .action {
      display: flex;
      padding-top: 11px;
      padding-right: 8px;
    }
  }
}

.accordion-item.material-transfer-details-component-item {
  .content {
    padding: 0px !important;

    .title::before {
      content: "" !important;
    }
  }
  div.content.product-details-dialog {
    padding: 16px !important;

    .top .title {
      padding: 0px;
      font-size: 16px !important;
      min-height: 0;
    }

    .body {
      padding-top: 24px !important;
      padding-bottom: 24px !important;
      .product-details-modal-value {
        margin: 0;
      }
    }
    .bottom {
      margin-top: 16px;
    }
  }
}
