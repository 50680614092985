.centered-container {
  height: 100%;
  display: flex;
  flex-direction: row;

  .centered-content {
    width: 100%;
    margin: auto;

    > * {
      margin: auto;
    }
  }
}
