@import "../../../../../../styling/variables.scss";

.image-details {
  .body {
    display: flex;

    img {
      width: 100%;
      object-fit: contain;
      -o-object-fit: contain;
    }
  }
  i.flipped {
    display: inline-block;
    transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    -ms-transform: scaleX(-1);
  }
}
