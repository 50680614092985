.tile.weather-tile {
  min-height: 200px;
}
.weather-tile .content .unit-link {
  display: inline-flex;
  margin-bottom: 20px;
  text-decoration: underline;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #242424;
}
