@import "../variables.scss";


.release-note-item {
  align-items: center;
  padding: 5px 0px;

  .release-note-type {
    margin: 0;
  }

  &:not(:last-of-type) {
    border-bottom: solid 1px;
  }
}


.dark {
  .release-note-item {
    border-color: $color-border-dark;
  }
}

.light {
  .release-note-item {
    border-color: $color-border-light;
  }
}