@import "../../variables.scss";

.light,
.dark {
  .overview-item {
    .card {
      &:hover {
        cursor: default;
      }

      p.content {
        margin-bottom: 0;
      }

      .overview-content {
        .site-project-date {
          display: flex;
          align-items: center;

          i {
            margin-right: $space-small;
          }
        }

        .space {
          padding: $space-base;
        }
      }

      .header {
        .go-to-site-project {
          cursor: pointer;
        }
      }
    }
  }

  .map-container {
    position: relative;

    .map-icons {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      bottom: $space-xl;
      left: $space-base;

      i {
        cursor: pointer;
        margin-bottom: $space-small;
        padding: $space-base;
        width: auto;
        border-radius: $space-small;
      }
    }
  }
}

.light {
  .overview-item {
    .card {
      .overview-content {
        .site-project-date {
          color: $color-gray-text-light;
        }
      }

      .red {
        color: $color-red-light;
      }

      .yellow {
        color: $color-yellow-light;
      }

      .green {
        color: $color-green-light;
      }
    }
  }

  .map-container {
    .map-icons {
      i {
        background-color: $color-blue-light;
        color: $color-tile-light;
      }
    }
  }
}

.dark {
  .overview-item {
    .card {
      .overview-content {
        .site-project-date {
          color: $color-gray-text-dark;
        }
      }

      .red {
        color: $color-red-dark;
      }

      .yellow {
        color: $color-yellow-dark;
      }

      .green {
        color: $color-green-dark;
      }
    }
  }

  .map-container {
    .map-icons {
      i {
        background-color: $color-blue-light;
      }
    }
  }
}

.user-details-container {
  display: flex;

  .user-details-icon {
    display: flex;
    flex: 0.3;
    font-size: 70px;
    justify-content: center;
    align-items: center;
  }

  .icon {
    margin-right: $space-small;
  }

  .user-details {
    padding: $space-base;
    border-left: 1px solid black;
  }
}

.user-details-container {
  display: flex;

  .i {
    display: flex;
  }

  .user-details-icon {
    display: flex;
    flex: 0.3;
    font-size: 70px;
    justify-content: center;
    align-items: center;
  }

  .icon {
    margin-right: $space-small;
  }

  .user-details {
    padding: $space-base;
  }
}

.bom-tile {
  display: flex;
  align-items: center;
  margin-top: $space-xl;

  .bom-summary {
    flex: 0.6;
  }
}
.bom-view-btn {
  padding-top: $space-xxl;
}
.light {
  .user-details-container {
    .user-details {
      border-left: 1px solid $color-border-light;
    }
  }
}

.dark {
  .user-details-container {
    .user-details {
      border-left: 1px solid $color-border-dark;
    }
  }
}

.site-project-contact {
  display: flex;
  align-items: center;
  margin: $space-base 0;

  .user-details {
    margin: 0 $space-small;
  }
}
