@import "../../../../../../styling/variables.scss";

.serial-number-item-container {
  padding-bottom: $space-base;
  + .serial-number-item-container {
    padding-top: $space-base;
  }
  .serial-number-subtitle {
    font-size: $text-size-subtitle;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .serial-number-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .justify-content-right {
    display: flex;
    justify-content: flex-end;
  }
  .inline-block {
    display: inline-block;
  }
  .serial-number-row {
    margin-top: $space-small;
    display: flex;
    .serial-number-field {
      justify-self: flex-start;
      flex: 1;
      input {
        margin-right: 0;
        width: 100%;
      }
    }
  }
  div.scan-button button.btn {
    margin-left: 8px;
  }
  .validation-button-row {
    margin-top: $space-base;
  }
  .validation-status {
    font-size: $text-sm;
    margin-right: $space-base;
  }
  .scan-button {
    display: flex;
    button {
      align-self: flex-end;
    }
  }
}

.serial-number-item-container + .item-list-header {
  margin-top: $space-base;
}

.dark {
  .serial-number-subtitle {
    color: $color-gray-text-dark;
  }
}

.light {
  .serial-number-subtitle {
    color: $color-gray-text-light;
  }
}

.justify-content-right {
  display: flex;
  justify-content: flex-end;
}
