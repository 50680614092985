@import "../../../../../styling/variables.scss";

.weather-item {
  & + .weather-item {
    margin-top: $space-large;
  }
  .weather-item-left {
    > * {
      opacity: 0.7;
    }

    .weather-date {
      font-weight: bold;
      opacity: 1;
    }
  }

  .weather-item-right {
    display: flex;

    p {
      align-self: center;
      text-transform: capitalize;
      margin: 0;
    }
    svg {
      align-self: center;
      margin-right: $space-large;
      width: 2rem;
    }
  }
}
.dark .weather-item .weather-item-right svg path {
  fill: $color-text-dark;
}
.light .weather-item .weather-item-right svg path {
  fill: $color-text-light;
}
