@import "../../../../src/styling/variables.scss";
.app .bom-main {
  display: flex;
  height: 100%;
  flex-direction: column;

  .bom-viewer-filter-row {
    flex: none;
    .tile .content .bom-filter {
      .dropdown {
        width: 100%;
      }
      .bom-commodity {
        padding: $space-small 0 $space-small 0;
        display: flex;
        align-items: center;
        justify-content: space-around;
        .pill {
          margin-bottom: $space-small;
        }
      }
      .bom-textfield-filter {
        padding-top: $space-base;
        .fullwidth {
          width: calc(100%);
        }
      }
      .bom-filter-close-btn {
        padding-top: $space-base;
        display: flex;
        flex-flow: row-reverse;
        .btn {
          margin-left: 8px;
        }
      }
    }
  }
  .row.bom-viewer {
    flex: 1 1;

    .bom-list-item {
      display: flex;
      flex-direction: column;
      height: 100%;

      & + .bom-list-item {
        .first-row {
          border-top: 1px solid;
        }
      }

      .first-row {
        display: flex;
        align-items: center;
        flex-direction: row;
        padding: $space-base 0 $space-base 0;
        margin-left: $space-base;
        margin-right: $space-base;

        .bom-product-title {
          flex: 1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .bom-product-subtitle {
          font-size: $text-size-subtitle;
          display: flex;
          align-items: center;
          .tooltip {
            display: flex;
          }
          .icon {
            margin-right: $space-small;
          }
        }

        .bom-product-details {
          padding-right: $space-base;
        }

        .bom-number-field + .remove-button {
          padding-left: $space-base;
          padding-right: $space-base;
        }
        .dropdown {
          width: 117px;
        }

        .product-details-modal-top-title {
          margin-bottom: $space-base;
        }
        .product-details-modal-row {
          display: flex;
          margin-bottom: $space-small;

          .product-details-modal-title {
            flex: 0.5;
            font-size: $text-size-subtitle;
            font-weight: bolder;
          }

          .product-details-modal-value {
            flex: 0.5;
            font-size: $text-size-subtitle;
          }
        }
      }

      .second-row {
        padding-top: 0;
        padding-right: $space-base;
        padding-bottom: 0;
        padding-left: $space-base;
        font-size: 0.9em;
        .title-value-pair {
          & + .title-value-pair {
            margin-top: $space-small;
          }
        }
        .dropdown,
        input {
          width: 100%;
        }
      }
    }

    .tile {
      padding: 0;
      margin: 0;
      .header {
        padding-top: $space-large;
        padding-right: $space-base;
        padding-bottom: $space-large;
        padding-left: $space-base;
        .btn {
          margin-bottom: 0;
        }
        .title {
          line-height: 30px;
        }
        .action {
          margin-top: 0;
          margin-bottom: 0;
        }
      }
      .content {
        display: flex;
      }
    }

    .list-container {
      flex: 1 1;
    }
    .content {
      display: flex;
      flex-flow: column;
      .pill-container {
        padding: 0 $space-base;
        margin-top: -12px;
      }
    }
  }
}

.light .app .bom-main .row.bom-viewer .bom-list-item {
  .first-row {
    border-color: $color-border-light;
  }
  .bom-number-field .dropdown .btn {
    background: $color-dropdown-btn-background-light;
    border-color: $color-border-light;
    color: $color-text-light;
    font-size: $text-sm;
    text-align: left;
  }

  .bom-product-subtitle {
    color: $color-gray-text-light;
    &.multiple-material-categories {
      color: $color-link-blue-light;
    }
  }
}

.dark .app .bom-main .row.bom-viewer .bom-list-item {
  .first-row {
    border-color: $color-border-dark;
  }
  .bom-number-field .dropdown .btn {
    background: $color-dropdown-btn-background-dark;
    border-color: $color-border-dark;
    color: $color-text-dark;
    font-size: $text-sm;
    text-align: left;
  }

  .bom-product-subtitle {
    color: $color-gray-text-dark;
    &.multiple-material-categories {
      color: $color-link-blue-dark;
    }
  }
}
