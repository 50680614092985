@import "../../../../styling/variables.scss";

/* HELPERS / MIXIN */
@mixin flexbox() {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.d-flex {
  @include flexbox();
}

.rm-scan-serial-numbers {
  padding: 0;
  .rm-scan-serial-numbers-item {
    @include flexbox();
    justify-content: space-between;
    align-items: flex-start;
    padding: 13px 0 14px 0;
    border-bottom: 1px dotted #bfbfbf;
    margin: 0 16px;
  }

.light .rm-scan-serial-numbers-item {
  background: #fafafa;
}
.dark .rm-scan-serial-numbers-item {
  background: #242424;
}
.rm-scan-serial-numbers-child-title {
  padding: 0 !important;
}
}
.light .rm-scan-serial-numbers-accordion-title {
  background: #fafafa;
}
.dark .rm-scan-serial-numbers-accordion-title {
  background: #242424; 
}

.rm-scan-serial-numbers-accordion {
  .content {
    padding: 0!important;
  }
}
.rm-scan-serial-numbers-accordion-title {
   .title{
    padding-left: 16px !important;
  }
  .rm-scan-serial-numbers-child .title {
    padding-left: 20px !important;
  }
  }

/*temp eds menu FIX: todo::after*/
.dark .appbar .title,
.light .appbar .title {
  left: 42px;
}

.dark .appbar .actions-left > .item,
.light .appbar .actions-left > .item {
  padding: 0 8px 0 16px;
}
