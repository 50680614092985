@import "../../variables.scss";

.title-value-pair-container {
  display: flex;

  .title-value-pair-title-column {
    align-self: center;

    .title-value-pair-title {
      opacity: 0.7;
      align-items: center;
    }
  }
}

.title-value-pair-container + .title-value-pair-container {
  margin-top: $space-base;
}
