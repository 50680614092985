@import "../../../../../styling/variables.scss";

.item-list-header {
  font-weight: bold;
  margin-top: $space-large;
  margin-bottom: $space-base;
  line-height: 28px;
}

.light {
  .item-list-header {
    border-bottom: 1px solid $color-border-light;
    padding-bottom: $space-large;
  }
}

.dark {
  .item-list-header {
    border-bottom: 1px solid $color-border-dark;
    padding-bottom: $space-large;
  }
}
