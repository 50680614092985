.rm-confirm-validation-dialog {
  .dialog .content {
    .top {
      border-bottom: 1px solid rgb(87, 87, 87);
      .title {
        flex: 0 0 auto;
        font-size: 16px;
        font-weight: 400;
        padding-bottom: 12px;
      }
    }
    .body {
      padding: 20px 0 0 0;
    }
    .bottom {
      display: flex !important;
      flex: 1 1 !important;
      min-height: 77px;
      flex-direction: column !important;
      align-content: space-around;
      justify-content: flex-start;
      align-items: stretch;
      .btn {
        margin: 4px 0 !important;
        display: block !important;
      }
    }
  }
}
