.checklist-item .image-container {
  .new-image,
  .saved-image {
    height: 90px;
    width: 75px;
    margin: 0 4px;
    display: inline-flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: center;
    img {
      max-height: 75px;
      max-width: 75px;
    }
    .loading {
      margin: 0 auto;
      padding-right: 16px;
    }
    span {
      font-size: 12px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .btn {
      text-align: center;
      margin: 0 auto;
    }
  }
}
