@import "../../../../styling/variables.scss";

.tile.site-project-documents-tile {
  min-height: 139px;
  .centered-container .centered-content {
    text-align: center;
    div + button {
      margin-top: $space-large;
    }
  }
  .documents-list {
    .document-row {
      & + .document-row {
        border-top: 1px dotted;
      }

      display: flex;
      align-items: center;
      padding-top: $space-base;
      padding-right: 0;
      padding-bottom: $space-base;
      padding-left: 0;
      min-height: 56px;
      .centered-container {
        margin: auto;
      }
      .icon-column {
        width: $space-large;
      }
      .text-column {
        flex: 1;
        padding-top: 0;
        padding-right: $space-base;
        padding-bottom: 0;
        padding-left: $space-base;
        min-width: 0;

        .title {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          cursor: pointer;
        }

        .meta-data {
          font-size: 12px;
          opacity: 0.7;
          margin-top: $space-small;
          margin-right: 0;
          margin-bottom: $space-small;
          margin-left: 0;

          .icon {
            margin-right: $space-small;
          }
        }
      }
      .action-column {
        .item > div {
          display: flex;
          line-height: 17px;
          .icon {
            margin-right: $space-small;
          }
        }
      }
    }
  }

  .bottom {
    margin-left: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    > label {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      padding: 0;
      & + .btn {
        margin: 0 0 0 $space-base;
      }
    }
  }
}

.document-details {
  .filename {
    word-wrap: break-word;
    word-break: break-all;
  }
  .restricted-note {
    margin-top: $space-large;
    font-size: 12px;
    display: flex;
    align-items: center;
    opacity: 0.7;
    .icon {
      margin-right: $space-small;
    }
  }
  .bottom .btn.loading {
    height: 28px;
    vertical-align: middle;
  }
}

.site-project-documents-pending-uploads-dialog {
  .file-list {
    margin-top: $space-large;
  }
}

.dark .tile.site-project-documents-tile {
  .document-row + .document-row {
    border-color: rgba(255, 255, 255, 0.6);
  }
}
.light .tile.site-project-documents-tile {
  .document-row + .document-row {
    border-color: rgba(0, 0, 0, 0.6);
  }
}
