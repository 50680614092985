@import "../../../styling/variables.scss";

.dark {
  .checklist-wrapper .checklist-item {
    background-color: $color-nested-background-dark;
  }
}
.light {
  .checklist-wrapper .checklist-item {
    background-color: $color-tile-light-card;
  }
}
.checklist-view-error-message {
  text-align: center;
}
.checklist-wrapper {
  .checklist-item {
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
    border-radius: $space-small;
    padding: 12px;
    margin-bottom: $space-base;
    display: block;

    .checklist-item {
      box-shadow: none;
      margin-top: $space-base;
      border-radius: $space-small;
    }
    .title {
      padding-bottom: $space-base;
    }
    .description {
      padding-top: $space-small;
      padding-bottom: $space-large;
      font-size: $text-sm;
    }

    .fullwidth {
      width: 100%;
    }
    .number-input {
      display: flex;
      align-items: center;

      input {
        flex-grow: 1;
      }

      &.spinner .controls {
        display: flex;
      }
    }
    .comment {
      margin-top: $space-base;
    }
    .image-container {
      border: 1px dashed;
      border-color: $color-application-light;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      margin-bottom: $space-large;
      font-size: $text-sm;
      .image-container-no-images-textfield {
        text-align: center;
        width: 100%;
        padding: $space-base;
      }
      .new-image-upload-container {
        margin: $space-small;
      }
    }
    .image-button {
      display: flex;
      padding: $space-small;
      margin-top: $space-small;
      justify-content: center;
      align-items: center;
    }
    .switch {
      display: flex;
    }
    .dropdown {
      width: 100%;
    }
    .datepicker {
      .with-icon {
        width: 100%;
        text-align: center;
      }
    }
    .sub-section {
      text-indent: $space-small;
      margin-top: $space-large;
    }
    .switch-container {
      display: flex;
      .title {
        flex: 1;
      }
    }
  }
  .content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    .btn {
      -ms-flex-item-align: baseline;
      align-self: baseline;
      text-align: left;
    }
  }
}
