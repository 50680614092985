@import "../../../../../styling/variables.scss";

.review-kpi-row {
  display: flex;
  justify-content: space-around;
  margin-top: $space-large;
}
.review-view-loader-container {
  height: 300px;
}
