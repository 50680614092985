@import "../../../../styling/variables.scss";

.deviation-tile {
  > div {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0 !important;
  }
  &-content {
    display: flex;
    flex-grow: 3;
    flex-direction: column;
    flex-basis: 45%;
  }
  &-number-fields {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 45%;
    align-items: flex-end;
  }
  &-product-number {
    margin: 0;
  }
}
.deviation-accordian-item-child {
  padding: 0 !important;
}
.light .deviation-tile{
  border-bottom: 1px solid $color-border-light;
}

.dark .deviation-tile {
  border-bottom: 1px solid $color-border-dark;
}

.report-deviation-accordian {
  .deviation-accordian-item {
    .content {
      padding: 0 !important;
    }
    .title {
      padding-left: 16px !important;
    }
  }
  .deviation-accordian .title {
    padding-left: 20px !important;
  }
}
.dark .deviation-accordian-item {
  background: #242424;
}

.light .deviation-accordian-item {
  background: #fafafa;
}

.deviation-tile-content {
  padding-left: 4px;
}

.deviation-margin-top {
  &-5 {
    display: inline;
    margin-top: 5px;
  }
  &-8 {
    display: inline;
    margin-top: 8px;
  }
  &-10 {
    display: inline;
    margin-top: 10px;
  }
}
