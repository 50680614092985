@import "../../variables.scss";

.overview-item {
  display: flex;

  .overview-list-item {
    .content {
      display: flex;
      flex-direction: column;

      .overview-content {
        flex: 1;
        margin-bottom: $space-large;
      }

      .actions {
        margin-top: auto;

        .btn {
          text-align: center;
        }

        .btn,
        .download-btn {
          display: inline-block;
          width: 100px;
          vertical-align: middle;
        }

        .btn ~ .download-btn {
          margin-left: $space-base;
        }

        .download-btn {
          display: inline-block;
          width: 60px;

          .btn {
            width: 60px;

            .icon {
              margin-right: 0;
            }
          }

          .loading {
            height: 28px;
          }
        }
      }
    }
  }
}
